import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: resolve => require(['@/views/login/LoginView'], resolve)
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
/*const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {undefined
  return originalPush.call(this, location).catch(err => err)
}*/

export default router

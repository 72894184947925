export default [
    {
        path: '/home',
        name: 'ShouYe',
        meta: {
            title: '系统首页'
        },
        component: resolve => require(['@/views/ShouyeView'], resolve)
    },
 /*   {
        path: '/menuMag',
        name: 'MenuManage',
        meta: {
            title: '菜单管理'
        },
        component: resolve => require(['@/views/system/MenuMag'], resolve)
    },*/
    {
        path: '/authMag',
        name: 'AuthManage',
        meta: {
            title: '权限管理'
        },
        component: resolve => require(['@/views/system/UserMag'], resolve)
    },
    {
        path: '/plat/noticeUpdate',
        name: 'NoticeUpdate',
        meta: {
            title: '编辑',
            activePath:"/plat/noticeList"
        },
        component: resolve => require(['@/views/plat/NoticeUpdate'], resolve)
    },
    {
        path: '/fastFish/videoList',
        name: 'VideoList',
        meta: {
            title: '视频列表',
            activePath:"/fastFish/courseList"
        },
        component: resolve => require(['@/views/fastFish/VideoList'], resolve)
    },
    {
        path: '/fastFish/banner',
        name: 'fastFish/banner',
        meta: {
            title: '轮播图设置'
        },
        component: resolve => require(['@/views/fastFish/SetBanner'], resolve)
    },
    {
        path: '/fastFish/course',
        name: 'fastFish/course',
        meta: {
            title: '课程列表'
        },
        component: resolve => require(['@/views/fastFish/CourseList'], resolve)
    },
];

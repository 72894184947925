<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style>
[class*=" el-icon-lx"], [class^=el-icon-lx] {
  font-family: lx-iconfont !important;
}
* {
  margin: 0;
  padding: 0;
}

html,
body,
#app,
.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
}

body {
  font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
}

a {
  text-decoration: none
}

</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Cookies from "js-cookie";
import './assets/style/variables.less'

import request from "@/utils/request";
import {baseImageUrl} from "@/utils/common";
import YtInputNumber from "@/components/YtInputNumber";
import UploadSingleImage from "@/components/UploadSingleImage";

import jquery from 'jquery'

window.jquery = window.$ = jquery
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_editor.min.css'
import 'froala-editor/js/froala_editor.pkgd.min'
import 'froala-editor/js/languages/zh_cn'
import 'froala-editor/js/plugins.pkgd.min'
import VueFroala from 'vue-froala-wysiwyg'

Vue.use(VueFroala)

Vue.use(ElementUI)

Vue.component("YtInputNumber", YtInputNumber);
Vue.component("UploadSingleImage", UploadSingleImage);

Vue.prototype.$http = request;
Vue.prototype.$baseImageUrl = baseImageUrl;

Vue.config.productionTip = false

const TOKEN = () => {
  return Cookies.get('ydd_TOKEN')
};
if (TOKEN()) {
  store.dispatch('fetchMenuList')
}
// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {

  // 判断该路由是否需要登录权限
  if(to.path !== "/login"){
    let Authorization = TOKEN();
    if (Authorization) {
      next();
    } else {
      next({
        path: "/login",
        // 将跳转的路由path作为参数，登录成功后跳转到该路由
        query: {redirect: to.fullPath}
      });
    }
  }else {
    next();
  }

});

// 跳转后返回顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
